.Loader {
  display: block;
  width: 35px !important;
}

.content {
  width: 26px;
  height: 26px;
  position: relative;
  top: 0;
  left: -12px;
  overflow: visible;
  z-index: 1;

  .orange {
    z-index: 3;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: -25%;
    fill: #f26724;
    overflow: visible;

    animation: scaleAnimation205 0.6s, colourAnimation 6s;
    animation-timing-function: cubic-bezier(0.3, 0, 0.3, 1);
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  .midGrey {
    z-index: 2;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 45%;
    fill: #f26724;
    overflow: visible;

    animation: scaleAnimation205 0.6s, colourAnimation 6s;
    animation-timing-function: cubic-bezier(0.3, 0, 0.3, 1);
    animation-delay: 0.2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  .smallGrey {
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 115%;
    fill: #f26724;
    overflow: visible;

    animation: scaleAnimation205 0.6s, colourAnimation 6s;
    animation-timing-function: cubic-bezier(0.3, 0, 0.3, 1);
    animation-delay: 0.4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}

@keyframes scaleAnimation205 {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes colourAnimation {
  0% {
    fill: #f26724;
  }
  100% {
    fill: #f26724;
  }
}
